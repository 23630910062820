import axios from "axios";
import { url, dispatchLoading } from "../utils";

const notificationUrl = url + "notification";

export const GetNotification = (pageId, itemPerPage) => async (dispatch) => {
	dispatchLoading(dispatch, "notification", true);
	axios
		.get(notificationUrl, { params: { pageId, itemPerPage: itemPerPage ? itemPerPage : 10 }, withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				dispatch({ type: "NOTIFICATION_LIST_SUCCESS", payload: result?.data?.data?.notification, count: result?.data?.data?.count, pageId: pageId });
			}
			dispatchLoading(dispatch, "notification", false);
		})
		.catch((err) => {});
};
export const SetNotificationRead = (data) => async (dispatch) => {
	dispatchLoading(dispatch, "notification", true);
	axios
		.post(notificationUrl + "/read", { notificationIdList: data }, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				dispatch({ type: "NOTIFICATION_READ_SUCCESS" });
			}
			dispatchLoading(dispatch, "notification", false);
		})
		.catch((err) => {});
};
export const FCM_TOKEN = (token) => async (dispatch) => {
	dispatchLoading(dispatch, "notification", true);
	axios
		.post(url + "auth/profile/fcm", { token }, { withCredentials: true })
		.then((result) => {
			dispatchLoading(dispatch, "notification", false);
		})
		.catch((err) => {});
};
