import axios from "axios";
import { url, dispatchLoading, dispatchToast, dispatchError, catchHandler, elseHandler, alias } from "../../utils";

// --- Actions --- //
const authUrl = url;

export const Login = (role, email, password, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .post(authUrl + "auth/login", { role, email, password }, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "AUTH_LOGIN_SUCCESS", payload: { role } });
        if (role === "customer") {
          navigate("/");
        }
        dispatchError(dispatch, "auth", undefined);
      } else elseHandler(dispatch, "auth", result?.data);
      dispatchLoading(dispatch, "auth", false);
    })
    .catch(catchHandler(dispatch, "auth"));
};
export const ForgotPasswordMail = (role, email, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .post(authUrl + "auth/forgot-password", { role, email }, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "AUTH_FORGOTPASS_MAIL_SUCCESS",
          payload: result?.data?.msg,
        });
        dispatchToast(dispatch, "success", "Reset password request sent to your email successfully");
        navigate("/auth/customer/");
        dispatchError(dispatch, "auth", undefined);
      } else elseHandler(dispatch, "auth", result?.data);
      dispatchLoading(dispatch, "auth", false);
    })
    .catch(catchHandler(dispatch, "auth"));
};
export const ForgotPassword = (role, token, password, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .put(authUrl + "auth/forgot-password", { role, token, password }, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "AUTH_FORGOTPASS_SUCCESS",
          payload: result?.data?.msg,
        });
        dispatchToast(dispatch, "success", "Password Reset Successful");
        dispatchError(dispatch, "auth", undefined);
        navigate("/auth/" + role, { replace: true });
      } else elseHandler(dispatch, "auth", result?.data);
      dispatchLoading(dispatch, "auth", false);
    })
    .catch(catchHandler(dispatch, "auth"));
};
export const UpdatePassword = (oldPassword, newPassword, role, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .put(authUrl + "auth/update-password", { oldPassword, newPassword }, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "AUTH_UPDATE_PASS_SUCCESS",
          payload: result?.data?.msg,
        });
        dispatchToast(dispatch, "success", "Password Updated Successfully");
        dispatchError(dispatch, "auth", undefined);
        navigate(`/${role}/profile`);
      } else {
        if (result?.data?.msg === "Token Error") {
          dispatchToast(dispatch, "error", "Reset password link has been expired, Try with new one");
          dispatchError(dispatch, "auth", result?.data?.error);
        } else {
          elseHandler(dispatch, "auth", result?.data);
        }
      }
      dispatchLoading(dispatch, "auth", false);
    })
    .catch(catchHandler(dispatch, "auth"));
};

export const CustomerSignUp = (data, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .post(authUrl + "customer", data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(Login("customer", data?.email, data?.password, navigate));
        dispatchToast(dispatch, "success", result?.data?.msg);
        dispatchError(dispatch, "auth", undefined);
        // navigate("/");
      } else elseHandler(dispatch, "auth", result?.data);
      dispatchLoading(dispatch, "auth", false);
    })
    .catch(catchHandler(dispatch, "auth"));
};
export const LoadUser = () => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .get(authUrl + "auth/", { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "AUTH_LOAD_SUCCESS", payload: result?.data?.data });
        dispatchError(dispatch, "auth", undefined);
      } else {
        dispatch({ type: "AUTH_LOAD_FAILURE" });
        elseHandler(dispatch, "auth", result?.data);
      }
      dispatchLoading(dispatch, "auth", false);
    })
    .catch((err) => {
      dispatch({ type: "AUTH_LOAD_FAILURE" });
      dispatchLoading(dispatch, "auth", false);
    });
};
export const Logout = () => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .get(authUrl + "auth/logout", { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "AUTH_LOGOUT_SUCCESS" });
        dispatchError(dispatch, "auth", undefined);
        dispatchToast(dispatch, "success", "Logout Successful");
      } else elseHandler(dispatch, "auth", result?.data);

      dispatchLoading(dispatch, "auth", false);
    })
    .catch(catchHandler(dispatch, "auth"));
};
export const DeleteProfilePhoto = (photoId, successCB) => async (dispatch) => {
  dispatchLoading(dispatch, "profile", true);
  axios
    .delete(authUrl + "auth/profile/upload", {
      data: { photoId },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        successCB();
        dispatchError(dispatch, "profile", undefined);
      } else elseHandler(dispatch, "profile", result?.data);
      dispatchLoading(dispatch, "profile", false);
    })
    .catch(catchHandler(dispatch, "profile"));
};

// Google Login
export const GoogleLoginInit = () => async (dispatch) => {
  // dispatchLoading(dispatch, "auth", true);
  dispatchLoading(dispatch, "google", true);
  axios
    .get(authUrl + "auth/google", { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        window.location.href = result?.data?.data?.url;
        dispatchError(dispatch, "auth", undefined);
      } else elseHandler(dispatch, "auth", result?.data);
      // dispatchLoading(dispatch, "auth", false);
      // dispatchLoading(dispatch, "auth", false);
      dispatchLoading(dispatch, "google", false);
    })
    .catch((err) => {
      dispatch({ type: "AUTH_LOAD_FAILURE" });
      // dispatchLoading(dispatch, "auth", false);
      dispatchLoading(dispatch, "google", false);
    });
};
export const FacebookLoginInit = (data) => async (dispatch) => {
  // dispatchLoading(dispatch, "auth", true);
  dispatchLoading(dispatch, "facebook", true);
  axios
    .post(authUrl + "auth/facebook", data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        window.location.href = result?.data?.data?.url;
        dispatchError(dispatch, "auth", undefined);
        dispatchLoading(dispatch, "facebook", false);
      } else elseHandler(dispatch, "auth", result?.data);
      // dispatchLoading(dispatch, "auth", false);
      // dispatchLoading(dispatch, "auth", false);
    })
    .catch((err) => {
      dispatch({ type: "AUTH_LOAD_FAILURE" });
      // dispatchLoading(dispatch, "auth", false);
      dispatchLoading(dispatch, "facebook", false);
    });
};
