import { Image, Modal, Upload } from "antd";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import svg_icon from "../../../Assets/svg_icon";
import { documentImageUrl, url } from "../../../utils";
import { useEffect } from "react";
import { DeleteDocument, DeleteExtraDocument } from "../../redux/Booking";
import { documentFailureIcon, documentSuccessIcon, carDetailsNoCar } from "../../Component/ImageList";
import Icons from "../../../Auth/assets/Icons";
import crossRedDotIcon from "../../../Assets/Common/cross-redDot.svg";
import axios from "axios";

const UploadIdentityPhotos = ({
  documents,
  bId,
  nameOfClass,
  title,
  extraDriverCheck,
  nameOfExtraDriverClass,
  nmDriver,
  isSearchList,
  bookingListType,
  extraDrivers,
  isExtra,
  dId,
}) => {
  const { t } = useTranslation();

  const [drivingFront, setDrivingFront] = useState();
  const [drivingBack, setDrivingBack] = useState();
  const [govtId, setGovtId] = useState();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPurpose, setModalPurpose] = useState(false);

  const [bookingType, setBookingType] = useState(bookingListType);

  const dispatch = useDispatch();

  const { role } = useSelector((state) => state.auth);

  useEffect(() => {
    if (documents) {
      setDrivingFront(documents?.drivingFront !== null ? documents?.drivingFront : undefined);
      setDrivingBack(documents?.drivingBack !== null ? documents?.drivingBack : undefined);
      setGovtId(documents?.govtId !== null ? documents?.govtId : undefined);
    } else {
      setDrivingFront(undefined);
      setDrivingBack(undefined);
      setGovtId(undefined);
    }
  }, [bId, documents, dispatch]);

  useEffect(() => {
    if (bookingListType === undefined) {
      if (window.location?.hash != undefined || window.location?.hash != null) {
        const a = window.location?.hash?.substring(1);
        if (a.includes("_")) {
          const b = a.split("_");
          if (["active", "upcoming", "request", "completed", "cancelled", "expired"].includes(b?.[0])) {
            setBookingType(b?.[0]);
          }
        } else if (["active", "upcoming", "request", "completed", "cancelled", "expired"].includes(a)) {
          setBookingType(a);
        }
      }
    }
  }, [bookingListType]);

  const errorHandler = () => {
    setModalOpen(true);
    setModalPurpose(false);
  };

  const successHandler = () => {
    setModalOpen(true);
    setModalPurpose(true);
  };

  const handleReduxImageUpdate = (isSearchList, bId, bookingListType, uid, value) => {
    dispatch({
      type: "BOOKING_IDENTY_DOCUMENT_UPDATE",
      payload: {
        isSearchList: isSearchList,
        bookingId: bId,
        type: bookingType,
        documentType: uid,
        documentValue: value,
        isExtra: isExtra ? "extraDrivers" : "documents",
        driverId: dId - 1 || 0,
        role: role,
      },
    });
  };

  const UploadA = ({ uid, value, setValue, label }) => {
    const [cancelToken, setCancelToken] = useState(null);

    const removeImage = () => {
      setValue(undefined);
      if (isExtra) {
        dispatch(DeleteExtraDocument(value, bId, dId, uid, () => setValue(undefined)));
      } else {
        dispatch(DeleteDocument(value, bId, uid, () => setValue(undefined)));
      }
      handleReduxImageUpdate(isSearchList, bId, bookingListType, uid, null);
    };

    const uploadProps = {
      className: `image-upload-grid before ${value !== undefined ? "documentUploaded" : ""}`,
      accept: "image/jpeg,image/jpg,image/png",
      action: isExtra ? `${url}/booking/confirm/upload/extra` : `${url}/booking/confirm/upload`,
      maxCount: 1,
      method: "POST",
      withCredentials: true,
      showUploadList: { showPreviewIcon: true, previewIcon: Icons.eye },
      progress: { strokeWidth: 8, showInfo: true },
      data: isExtra ? { bookingId: bId, driverId: dId, name: uid } : { bookingId: bId, name: uid },
      listType: "picture-card",
      onChange(e) {
        setValue(e.file.response?.data?.photo);
        if (e?.file?.status === "done") successHandler();
        else if (e?.file?.status !== "uploading") errorHandler();

        setTimeout(() => {
          handleReduxImageUpdate(isSearchList, bId, bookingListType, uid, e.file.response?.data?.photo);
        }, 1000);
      },
      customRequest({ action, data, file, filename, headers, withCredentials }) {
        const formData = new FormData();
        if (data) {
          Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
          });
        }
        formData.append(filename, file);
        axios
          .post(action, formData, {
            withCredentials,
            headers,
          })
          .then((result) => {
            setValue(result?.data?.data?.photo);
            setTimeout(() => {
              handleReduxImageUpdate(isSearchList, bId, bookingListType, uid, result?.data?.data?.photo);
            }, 1000);
            successHandler();
          })
          .catch((error) => {
            errorHandler();
          });
      },
    };

    return (
      <>
        {value === null || value === undefined ? (
          <Upload {...uploadProps}>
            <div>
              {svg_icon?.c_bookingConfirm}
              <p className="ff_rg f16 mb-0 mt-1 txt-primary">{t(label)}</p>
            </div>
          </Upload>
        ) : (
          <div className="position-relative w-100 d-flex align-items-center justify-content-center afterUpload border-n border-1">
            <div className="h-100">
              <img src={crossRedDotIcon} alt="removeItem" className="img-fluid remove-img-btn cursor-pointer" onClick={removeImage} />
              <Image.PreviewGroup className="h-100">
                <Image src={`${documentImageUrl}/${value}`} className="h-100" onError={(event) => (event.target.src = carDetailsNoCar)} />
              </Image.PreviewGroup>
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <div className={`BookingDocUpload ${nameOfClass}`}>
        <h4 className="ff_bd f20 mb-4 txt-primary">
          {title}
          <div className="f12 txt-dark ff_md 2v">({t("customer.booking.bookingConfirm.labels.validFormat")})</div>
        </h4>

        <div className="row my-4 mb-0 gy-3 gy-lg-0">
          <div className="col-12 col-lg-4">
            <UploadA uid="drivingFront" value={drivingFront} setValue={setDrivingFront} label="customer.booking.bookingConfirm.labels.scanFront" />
          </div>
          <div className="col-12 col-lg-4">
            <UploadA uid="drivingBack" value={drivingBack} setValue={setDrivingBack} label="customer.booking.bookingConfirm.labels.scanBack" />
          </div>
          <div className="col-12 col-lg-4">
            <UploadA uid="govtId" value={govtId} setValue={setGovtId} label="customer.booking.bookingConfirm.labels.uploadGovID" />
          </div>
        </div>
      </div>

      {extraDriverCheck === true && (
        <div className={`BookingDocUpload ${nameOfExtraDriverClass} extraDriver`}>
          {Array.from({ length: nmDriver }, (_, i) => {
            return (
              <React.Fragment key={i}>
                <div className="row mb-0 mt-0">
                  <h4 className="ff_bd f20 mt-4 mb-0 txt-primary">
                    {t("titles.extraDriver")} {i + 1}
                  </h4>
                </div>
                <UploadIdentityPhotos
                  isExtra={true}
                  dId={i + 1}
                  bId={bId}
                  documents={
                    Object.values(extraDrivers)?.filter((ele) => {
                      return ele?.driverId === i + 1;
                    })[0]
                  }
                />
              </React.Fragment>
            );
          })}
        </div>
      )}

      <Modal
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        maskClosable={false}
        onCancel={() => {}}
        footer={[]}
        closable={false}
        centered
        className=" modelMaxHeight customerAntModal"
      >
        <div className="CarAvailable text-center py-5">
          {modalPurpose === true ? (
            <>
              <img src={documentSuccessIcon} alt="documentSuccess" className="img-fluid mb-3" />
              <p className="ff_md f24 mb-2">{t("labels.documentUploadSuccess")}</p>
              {role === "customer" && <p className="ff_rg f14 mb-2">{t("customer.booking.bookingConfirm.labels.bringOriginal")}</p>}
            </>
          ) : (
            <>
              <img src={documentFailureIcon} alt="documentFailure" className="img-fluid mb-3" />
              <p className="ff_md f24 mb-2 text-danger">{t("labels.documentUploadFailed")}</p>
              <p className="ff_rg f14 mb-2">
                {t("customer.booking.bookingConfirm.labels.ifUnable")}
                <b>carrental@gmail.com</b>
              </p>
            </>
          )}
          <div className="d-flex justify-content-center antDButton GapOne mt-4">
            <button className="antDCancel c-primary txt-light ff_md f14 minWidth100" onClick={() => setModalOpen(false)}>
              {t("buttons.okay")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const updateIdentyValue = (obj, keys, newValue) => {
  const updateNested = (nestedObj, currentIndex) => {
    if (currentIndex === keys.length - 1) {
      return { ...nestedObj, [keys[currentIndex]]: newValue };
    }

    const key = keys[currentIndex];
    const updatedNestedObj = { ...nestedObj };

    updatedNestedObj[key] = updateNested(nestedObj[key], currentIndex + 1);

    return updatedNestedObj;
  };

  return updateNested(obj, 0);
};

export default UploadIdentityPhotos;
