import axios from "axios";
import { url, dispatchLoading, dispatchError, catchHandler, elseHandler } from "../../utils";

const dashboardUrl = url + "dashboard";

export const DashboardData = (startDate, endDate) => async (dispatch) => {
  dispatchLoading(dispatch, "dashboard", true);
  let noDiskCache = { noDiskCache: 1, timeStamp: `${new Date().getTime()}` };
  axios
    .get(dashboardUrl + "/dashboard", { params: { startDate, endDate, noDiskCache }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "DASHBOARD_SUCCESS", payload: { name: "DashboardSection", value: result?.data?.data } });
        dispatchError(dispatch, "dashboard", undefined);
      } else elseHandler(dispatch, "dashboard", result?.data);
      dispatchLoading(dispatch, "dashboard", false);
    })
    .catch(catchHandler(dispatch, "dashboard"));
};

export const BookingData = () => async (dispatch) => {
  dispatchLoading(dispatch, "dashboard", true);
  let noCacheParams = { noCache: 1, timeStamp: `${new Date().getTime()}` };
  axios
    .get(dashboardUrl + "/booking", { params: noCacheParams, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "DASHBOARD_SUCCESS", payload: { name: "Booking", value: result?.data?.data } });
        dispatchError(dispatch, "dashboard", undefined);
      } else elseHandler(dispatch, "dashboard", result?.data);
      dispatchLoading(dispatch, "dashboard", false);
    })
    .catch(catchHandler(dispatch, "dashboard"));
};

export const RecentCarBookingData = () => async (dispatch) => {
  dispatchLoading(dispatch, "dashboard", true);
  let noCacheParams = { noCache: 1, timeStamp: `${new Date().getTime()}` };
  axios
    .get(dashboardUrl + "/recent-car-booking", { params: noCacheParams, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "DASHBOARD_SUCCESS", payload: { name: "RecentCarBooking", value: result?.data?.data } });
        dispatchError(dispatch, "dashboard", undefined);
      } else elseHandler(dispatch, "dashboard", result?.data);
      dispatchLoading(dispatch, "dashboard", false);
    })
    .catch(catchHandler(dispatch, "dashboard"));
};

export const MostPopularBookingData = () => async (dispatch) => {
  dispatchLoading(dispatch, "dashboard", true);
  axios
    .get(dashboardUrl + "/most-popular-booking", { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "DASHBOARD_SUCCESS", payload: { name: "MostPopularBooking", value: result?.data?.data } });
        dispatchError(dispatch, "dashboard", undefined);
      } else elseHandler(dispatch, "dashboard", result?.data);
      dispatchLoading(dispatch, "dashboard", false);
    })
    .catch(catchHandler(dispatch, "dashboard"));
};
