import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { updateIdentyValue } from "./Customer/views/Booking/UploadIdentityPhotos";

const authReducer = (
  state = {
    role: -1,
    isLoggedIn: -1,
    isAuthenticated: -1,
    isSocialMedia: true,
  },
  action
) => {
  switch (action.type) {
    case "AUTH_LOAD_SUCCESS":
      return {
        ...state,
        isAuthenticated: action?.payload?.isAuthenticated,
        role: action?.payload?.role,
        isLoggedIn: true,
        user: action?.payload?.result,
      };

    case "AUTH_LOAD_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        isLoggedIn: false,
        role: -1,
      };
    case "AUTH_LOGIN_SUCCESS":
      return {
        ...state,
        role: action.payload.role,
        isLoggedIn: true,
      };

    case "AUTH_LOGOUT_SUCCESS":
      return {
        ...state,
        isAuthenticated: false,
        isLoggedIn: false,
        user: undefined,
        role: -1,
      };
    case "AUTH_FORGOTPASS_MAIL_SUCCESS":
    case "AUTH_FORGOTPASS_SUCCESS":
      return { ...state, error: undefined };
    case "AUTH_USER_CREATE_SUCCESS":
      return { ...state };
    default:
      return state;
  }
};
const bookingReducer = (
  state = {
    carAvailable: undefined,
    location: undefined,
    dateTime: undefined,
    driverDetail: undefined,
    carId: undefined,
    rentalOptions: undefined,
    rentalOptionsCost: undefined,
    costDetails: undefined,
    paymentDetails: undefined,
    createdAt: undefined,
    bookingId: undefined,
    customerProfilePic: undefined,
    searchlist: undefined,
  },
  action
) => {
  switch (action.type) {
    case "BOOKING_UPDATE_CAR_PHOTOS":
      let tempBookingUpdateList = {};
      let carDataKeys = [];
      let carNewValue = "";
      let carNewData;
      if (action.payload?.isSearchList) {
        tempBookingUpdateList = { ...state.searchlist };
        // tempBookingUpdateList[action.payload?.bookingId]['ride'][action.payload?.rideType]['photos'][action.payload?.carView]['photo'] = action.payload?.imageValue;
        carDataKeys = [action.payload?.bookingId, "ride", action.payload?.rideType, "photos", action.payload?.carView, "photo"];
        carNewValue = action.payload?.imageValue;
        carNewData = updateIdentyValue(tempBookingUpdateList, carDataKeys, carNewValue);
        return { ...state, searchlist: { ...carNewData } };
      } else {
        tempBookingUpdateList = { ...state.list };
        carDataKeys = [action.payload?.type, action.payload?.bookingId, "ride", action.payload?.rideType, "photos", action.payload?.carView, "photo"];
        carNewValue = action.payload?.imageValue;
        carNewData = updateIdentyValue(tempBookingUpdateList, carDataKeys, carNewValue);
        // tempBookingUpdateList[action.payload?.type][action.payload?.bookingId]['ride'][action.payload?.rideType]['photos'][action.payload?.carView]['photo'] = action.payload?.imageValue;
        return { ...state, list: { ...carNewData } };
      }

    case "BOOKING_IDENTY_DOCUMENT_UPDATE":
      let tempBookingList = {};
      let dataKeys = [];
      let newValue = "";
      let newData;
      if (action.payload?.role == "customer") {
        tempBookingList = { ...state };
        tempBookingList["extraDrivers"][action.payload?.dId][action.payload?.documentType] = action.payload?.documentValue;
        return { ...tempBookingList };
      } else {
        if (action.payload?.isSearchList) {
          tempBookingList = { ...state.searchlist };
          if (action.payload?.isExtra === "documents") {
            // tempBookingList[action.payload?.bookingId][action.payload?.isExtra][action.payload?.documentType] = action.payload?.documentValue;
            dataKeys = [action.payload?.bookingId, action.payload?.isExtra, action.payload?.documentType];
            newValue = action.payload?.documentValue;
            newData = updateIdentyValue(tempBookingList, dataKeys, newValue);
          } else {
            dataKeys = [action.payload?.bookingId, action.payload?.isExtra, action.payload?.driverId, action.payload?.documentType];
            newValue = action.payload?.documentValue;
            newData = updateIdentyValue(tempBookingList, dataKeys, newValue);
          }
          return { ...state, searchlist: { ...newData } };
        } else {
          tempBookingList = { ...state.list };
          if (action.payload?.isExtra === "documents") {
            // tempBookingList[action.payload?.type][action.payload?.bookingId][action.payload?.isExtra][action.payload?.documentType] = action.payload?.documentValue;
            dataKeys = [action.payload?.type, action.payload?.bookingId, action.payload?.isExtra, action.payload?.documentType];
            newValue = action.payload?.documentValue;
            newData = updateIdentyValue(tempBookingList, dataKeys, newValue);
          } else {
            dataKeys = [action.payload?.type, action.payload?.bookingId, action.payload?.isExtra, action.payload?.driverId, action.payload?.documentType];
            newValue = action.payload?.documentValue;
            newData = updateIdentyValue(tempBookingList, dataKeys, newValue);
          }
          return { ...state, list: { ...newData } };
        }
      }
    case "BOOKING_LIST_COUNT":
      return { ...state, bookingListCount: { ...action.payload } };
    case "BOOKING_LIST_TAB_SUCCESS":
      let bookingListTab;
      if (state.list != undefined && action.payload?.pageId != 0) {
        bookingListTab = { ...state.list[action.payload.type] };
      } else {
        bookingListTab = {};
      }
      action.payload.data.forEach((ele) => {
        bookingListTab[ele._id] = ele;
      });
      return { ...state, list: { ...state.list, [`${action.payload.type}`]: bookingListTab } };
    case "BOOKING_LIST_SUCCESS":
      let bList = state.list !== undefined ? { ...state.list } : { upcoming: {}, cancelled: {}, active: {}, completed: {}, request: {}, expired: {} };
      action.payload.forEach((ele) => {
        if (ele?.rideStatus === "upcoming") {
          if (new Date(`${ele?.dateTime?.to}`).getTime() < Date.now()) bList["expired"][ele._id] = ele;
          else if (ele?.requireApproval === true && ele?.rideApproved === null) bList["request"][ele._id] = ele;
          else bList[ele?.rideStatus][ele._id] = ele;
        } else bList[ele?.rideStatus][ele._id] = ele;
      });
      return { ...state, list: bList };
    case "CAR_BOOKING_LIST_SUCCESS":
      let cbList = {};
      if (action.payload.length) {
        action.payload.forEach((ele) => (cbList[ele._id] = ele));
      } else {
        if (action.payload == "") cbList = "";
      }
      return { ...state, searchlist: { ...state.searchlist, ...cbList } };
    case "BOOKING_SEARCH_SUCCESS":
      let sList = {};
      if (action.payload.data.length) {
        action.payload.data.forEach((ele) => (sList[ele._id] = ele));
      } else {
        if (action.payload == "") sList = "";
      }
      let tempSearchList = action.payload.searchCount === 0 ? [] : { ...state.searchlist, ...sList };
      if (action.payload?.pageId === 0) tempSearchList = { ...sList };
      return { ...state, searchlist: tempSearchList, searchCount: action.payload.searchCount };
    case "BOOKING_SEARCH_CLEAR":
      return { ...state, searchlist: undefined, searchCount: 0 };
    case "BOOKING_LIST_UPDATE_SUCCESS":
      let nbList = { ...state.list };
      if (action.payload?.rideStatus === "upcoming" && action.payload?.requireApproval === true && action.payload?.rideApproved === null)
        nbList["request"][action.payload?._id] = action.payload;
      else nbList[action.payload?.rideStatus][action.payload?._id] = action.payload;
      return { ...state, list: nbList };
    case "BOOKING_CHECKAVAIL_SUCCESS":
      return { ...state, carAvailable: true };
    case "BOOKING_CHECKAVAIL_FAILURE":
      return { ...state, carAvailable: false };
    case "BOOKING_INIT_SUCCESS":
    case "BOOKING_LOAD_SUCCESS":
      let tempExtraDrivers = {};
      if (action.payload?.extraDrivers && action.payload?.extraDrivers.length) {
        action.payload?.extraDrivers.forEach((ele) => (tempExtraDrivers[ele._id] = ele));
      }
      return {
        ...state,
        location: action.payload?.location,
        dateTime: action.payload?.dateTime,
        driverDetail: action.payload?.driverDetail,
        carId: action.payload?.carId,
        refId: action.payload?.refId,
        rentalOptions: action.payload?.rentalOptions,
        rentalOptionsCost: action.payload?.rentalOptionsCost,
        costDetails: action.payload?.costDetails,
        paymentDetails: action.payload?.paymentDetails,
        createdAt: action.payload?.createdAt,
        bookingId: action.payload?._id,
        documents: action.payload?.documents,
        extraDrivers: tempExtraDrivers,
        ride: action.payload?.ride,
        rideStatus: action.payload?.rideStatus,
        rideApproved: action.payload?.rideApproved,
        requireApproval: action.payload?.requireApproval,
      };
    case "BOOKING_RENTALOPS_SUCCESS":
      return {
        ...state,
        timeDay: action.payload?.timeDay,
        timeHour: action.payload?.timeHour,
        rentalOptions: action.payload?.rentalOptions,
        rentalOptionsCost: action.payload?.rentalOptionsCost,
        costDetails: action.payload?.costDetails,
        driverDetail: action.payload?.driverDetail !== undefined ? action.payload?.driverDetail : state.driverDetail,
      };
    case "BOOKING_PROMOAPPLY_SUCCESS":
    case "BOOKING_PROMOREMOVE_SUCCESS":
      return {
        ...state,
        rentalOptionsCost: action.payload?.rentalOptionsCost,
        costDetails: action.payload?.costDetails,
      };
    case "BOOKING_PROFILEPIC_SUCCESS":
      return Object.assign({}, state, {
        customerProfilePic: { ...state.customerProfilePic, [`${action.payload.name}`]: action.payload.value },
        driverDetail: action.payload?.driverDetail,
      });
    case "BOOKING_DRIVERDETAIL_SUCCESS":
      return { ...state, driverDetail: action.payload.driverDetails };
    case "BOOKING_CANCELREASON_SUCCESS":
      return { ...state, reasonList: action.payload };
    case "BOOKING_AVAILABLE_DATE":
      return { ...state, availableDate: action.payload.data, availableMsg: action.payload.msg };
    case "BOOKING_FAIL_SUCCESS":
      return { ...state, failedBookingRefId: action.payload?.data };
    case "BOOKING_CLEAR":
      return {
        carAvailable: undefined,
        location: undefined,
        dateTime: undefined,
        driverDetail: undefined,
        carId: undefined,
        rentalOptions: undefined,
        rentalOptionsCost: undefined,
        costDetails: undefined,
        paymentDetails: undefined,
        createdAt: undefined,
        bookingId: undefined,
        customerProfilePic: undefined,
        searchlist: undefined,
      };
    default:
      return state;
  }
};
const carReducer = (
  state = {
    list: undefined,
    detail: undefined,
    brandList: undefined,
    typeList: undefined,
    featureList: undefined,
    recentCarId: undefined,
    carCount: 0,
    priorityCarId: undefined,
  },
  action
) => {
  switch (action.type) {
    case "PRIORITY_CAR_ID":
      return {
        ...state,
        priorityCarId: action.payload,
      };
    case "CAR_HOME_SUCCESS":
      return {
        ...state,
        homeCarList: action.payload,
      };
    case "CAR_LIST_SUCCESS":
      return {
        ...state,
        list: action.payload.list,
        priority: action.payload?.priority,
        carCount: action.payload.count,
        listCount: action.payload.listCount,
      };
    case "CAR_SEARCH_SUCCESS":
      return {
        ...state,
        search: action.payload.list,
        searchCount: action.payload.count,
      };
    case "CAR_SET":
      return Object.assign({}, state, {
        [`${action.payload.name}`]: action.payload.value,
      });
    case "CAR_REVIEW_SET":
      return Object.assign({}, state, {
        review: { [`${action.payload.name}`]: action.payload.value, ...state.review },
      });
    case "CAR_TOGGLEACTIVE_SUCCESS":
      return {
        ...state,
        detail: { ...state?.detail, isActive: !state?.detail?.isActive },
      };
    case "CAR_APPROVAL_SUCCESS":
      return {
        ...state,
        detail: { ...state?.detail, requireApproval: !state?.detail?.requireApproval },
      };
    case "CAR_PREMIUM_SUCCESS":
      return {
        ...state,
        detail: { ...state?.detail, premiumCar: !state?.detail?.premiumCar },
      };
    case "CAR_DROPDOWN_LIST":
      return {
        ...state,
        dropDownList: action.payload,
      };
    default:
      return state;
  }
};
const employeeReducer = (
  state = {
    list: undefined,
    employeeCount: 0,
  },
  action
) => {
  switch (action.type) {
    case "EMPLOYEE_ADD_SUCCESS":
      let temp = [...state.list];
      temp.push(action.payload);
      let tempEmployeeCount = state.employeeCount;
      tempEmployeeCount++;
      return {
        ...state,
        list: temp,
        employeeCount: tempEmployeeCount,
      };
    case "EMPLOYEE_SEARCH_SUCCESS":
      return {
        ...state,
        search: action.payload.list,
        searchCount: action.payload.count,
      };
    case "EMPLOYEE_LIST_SUCCESS":
      return {
        ...state,
        list: action.payload.list,
        [`${action.payload.type}Count`]: action.payload.count,
      };
    default:
      return state;
  }
};
const staticReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_FAQ_LIST_SUCCESS":
      return {
        ...state,
        faqList: action.payload,
      };
    case "STATIC_PAGE_LIST_SUCCESS":
      return {
        ...state,
        staticPageList: action.payload,
      };
    default:
      return state;
  }
};
const emailTemplateReducer = (state = {}, action) => {
  switch (action.type) {
    case "EMAIL_LIST_SUCCESS":
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};
const helpReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETED_HELP_LIST_SUCCESS":
      return {
        ...state,
        deletedHelpCategoryList: action.payload,
      };
    case "HELP_LIST_SUCCESS":
      return {
        ...state,
        helpCategoryList: action.payload,
      };
    case "HELP_VIEW_LIST_SUCCESS":
      return {
        ...state,
        helpCategoryViewList: action.payload,
      };
    case "HELP_ARTICLE_LIST_SUCCESS":
      return {
        ...state,
        helpArticleList: action.payload,
      };
    case "DELETED_HELP_ARTICLE_LIST_SUCCESS":
      return {
        ...state,
        deletedHelpArticleList: action.payload,
      };
    default:
      return state;
  }
};
const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case "NOTIFICATION_LIST_SUCCESS":
      if (action?.pageId === undefined || state?.pageId === action?.pageId) return state;
      let ap = action.payload;
      let tempNotification = state?.notification !== undefined ? state?.notification : [];
      return {
        ...state,
        pageId: action?.pageId,
        notification: [...tempNotification, ...ap],
        notificationCount: action.count,
        unreadNotificationId: action.payload
          .filter((ele) => {
            return ele?.read === false;
          })
          .map((ele) => ele?._id),
      };
    case "NOTIFICATION_TEMP_LIST_SUCCESS":
      return {
        ...state,
        notificationTemplateList: action.payload,
      };
    case "NOTIFICATION_READ_SUCCESS":
      return {
        ...state,
        unreadNotificationId: [],
      };
    case "NOTIFICATION_CLEAR":
      return {};
    default:
      return state;
  }
};
const promoReducer = (
  state = {
    list: undefined,
    promoCount: 0,
  },
  action
) => {
  switch (action.type) {
    case "PROMO_ADD_SUCCESS":
      let temp = [...state.list];
      temp.push(action.payload);
      let tempPromoCount = state.PromoCount;
      tempPromoCount++;
      return {
        ...state,
        list: temp,
        employeeCount: tempPromoCount,
      };
    case "PROMO_SEARCH_SUCCESS":
      return {
        ...state,
        search: action.payload.list,
        searchCount: action.payload.count,
      };
    case "PROMO_LIST_SUCCESS":
      return {
        ...state,
        list: action.payload.list,
        promoCount: action.payload.count,
      };
    default:
      return state;
  }
};
const errorReducer = (
  state = { authError: undefined, carError: undefined, employeeError: undefined, bookingError: undefined, promoError: undefined, helpError: undefined },
  action
) => {
  /**
   * UNIVERSAL ERROR HANDLER
   */
  switch (action.type) {
    case "SET_ERROR":
      let temp = {};
      if (action.payload?.status?.length !== undefined)
        action.payload?.status?.forEach((ele) => {
          if (ele.param === "_error")
            ele.nestedErrors.map((ele1) => {
              let a = ele1.param.split(".");
              if (a.length === 1) temp[a[0]] = ele1?.msg;
              else temp[a[a.length - 1]] = ele1?.msg;
            });
          else if (ele.param.includes("updates.")) temp[ele.param.split(".").slice(1).join(".")] = ele?.msg;
          else temp[ele.param] = ele?.msg;
        });

      return Object.assign({}, state, {
        [`${action.payload.scope}Error`]: temp,
      });
    default:
      return state;
  }
};
const loadingReducer = (
  state = {
    authLoading: false,
    carLoading: false,
    employeeLoading: false,
    bookingLoading: false,
    bookingListLoading: false,
    googleLoading: false,
    appleLoading: false,
    facebookLoading: false,
  },
  action
) => {
  /**
   * UNIVERSAL LOADING HANDLER
   */
  switch (action.type) {
    case "SET_LOADING":
      return Object.assign({}, state, {
        [`${action.payload.scope}Loading`]: action.payload.status,
      });
    default:
      return state;
  }
};
const earningReducer = (state = {}, action) => {
  switch (action.type) {
    case "LIST_EARNING":
    case "EARNING_SEARCH_SUCCESS":
      return { ...state, earningList: action.payload };
    case "FETCH_PAY_HISTORY":
      return Object.assign({}, state, {
        transactionList: {
          ...state.transactionList,
          [`${action.payload.name}`]: action.payload.data,
        },
      });
    case "EARNING_SEARCH_CLEAR":
      return { ...state, earningList: undefined };
    default:
      return state;
  }
};
const locationReducer = (
  state = {
    pickUpList: undefined,
    dropOffList: undefined,
  },
  action
) => {
  switch (action.type) {
    case "LOCATION_ADD_SUCCESS":
      let temp = [...state[action.payload.type]];
      temp.push(action.payload.data);
      return {
        ...state,
        [`${action.payload.type}`]: temp,
      };
    case "LOCATION_LIST_SUCCESS":
      return Object.assign({}, state, {
        [`${action.payload.type}`]: action.payload.data,
      });
    default:
      return state;
  }
};
const customerReducer = (
  state = {
    list: undefined,
    customerCount: 0,
  },
  action
) => {
  switch (action.type) {
    case "CUSTOMER_LIST_SUCCESS":
      return {
        ...state,
        [`${action.payload.type}`]: action.payload.list,
        [`${action.payload.type}Count`]: action.payload.count,
      };
    case "CUSTOMER_SEARCH_SUCCESS":
      return {
        ...state,
        search: action.payload.list,
        searchCount: action.payload.count,
      };
    default:
      return state;
  }
};
const settingReducer = (
  state = {
    booking: undefined,
    billing: undefined,
    contact: undefined,
    isSocialMedia: true,
  },
  action
) => {
  switch (action.type) {
    case "SETTING_FETCH_SUCCESS":
      return {
        ...state,
        booking: action.payload?.booking,
        billing: action.payload?.billing,
        contact: action.payload?.contact,
        socialMedia: action.payload?.socialMedia,
        isSocialMedia: action.payload?.isSocialMedia,
      };
    case "SOCIAL_MEDIA_LOGIN_TOGGLE":
      return {
        ...state,
        isSocialMedia: !state.isSocialMedia,
      };
    default:
      return state;
  }
};
const msgReducer = (state = { success: undefined, error: undefined }, action) => {
  /**
   * UNIVERSAL TOAST HANDLER
   */
  switch (action.type) {
    case "SET_TOAST":
      return Object.assign({}, state, {
        [`${action.payload.scope}`]: action.payload.status || "Something went wrong!",
      });
    case "CLEAR_TOAST":
      return { ...state, error: undefined, success: undefined };
    default:
      return state;
  }
};
const searchReducer = (
  state = {
    pickupDate: undefined,
    dropDate: undefined,
    searchPickupLocation: undefined,
    searchDropLocation: undefined,
    searchPickupLocationValue: undefined,
    searchDropLocationValue: undefined,
    filterDataCar: undefined,
  },
  action
) => {
  switch (action.type) {
    case "SEARCH_SET":
      return Object.assign({}, state, {
        [`${action.payload.name}`]: action.payload.value,
      });
    case "SEARCH_CLEAR_DATE":
      return {
        ...state,
        pickupDate: undefined,
        dropDate: undefined,
      };
    case "SEARCH_CLEAR":
      return {
        ...state,
        pickupDate: undefined,
        dropDate: undefined,
        searchPickupLocation: undefined,
        searchDropLocation: undefined,
        searchPickupLocationValue: undefined,
        searchDropLocationValue: undefined,
      };
    case "SEARCH_SET_LOCATION":
      return Object.assign({}, state, {
        [`search${action.payload.type}Location`]: action.payload.name,
        [`search${action.payload.type}LocationValue`]: action.payload.value,
      });
    default:
      return state;
  }
};
const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case "DASHBOARD_SUCCESS":
      return Object.assign({}, state, {
        [`${action.payload.name}`]: action.payload.value,
      });
    default:
      return state;
  }
};
const jobApplicantReducer = (state = { list: undefined }, action) => {
  switch (action.type) {
    case "JOB_LIST_SUCCESS":
      let tempList = {};
      action.payload.data.forEach((ele) => {
        tempList[ele?._id] = ele;
      });
      return { ...state, list: tempList };
    default:
      return state;
  }
};

const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    booking: bookingReducer,
    car: carReducer,
    employee: employeeReducer,
    error: errorReducer,
    loading: loadingReducer,
    location: locationReducer,
    msg: msgReducer,
    search: searchReducer,
    promo: promoReducer,
    customer: customerReducer,
    setting: settingReducer,
    dashboard: dashboardReducer,
    static: staticReducer,
    help: helpReducer,
    notification: notificationReducer,
    emailTemplate: emailTemplateReducer,
    earning: earningReducer,
    jobApplicant: jobApplicantReducer,
  }),
});

export default store;
