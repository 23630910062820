import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function ToastHandler() {
  // selector
  const { error, success } = useSelector((state) => state.msg);
  // use
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // useEffect
  useEffect(() => {
    if (error) {
      toast.error(t(error));
      dispatch({ type: "CLEAR_TOAST" });
    }
    if (success) {
      toast.success(t(success));
      dispatch({ type: "CLEAR_TOAST" });
    }
  }, [error, success, dispatch, t]);
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      limit={3}
      className={"carRental-ToastContainer"}
    />
  );
}

export default ToastHandler;
