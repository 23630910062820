import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
// Redux Action
import { LoadUser, Logout } from "../Auth/redux/Auth";
import { SetNotificationRead } from "../Redux/Notification";
// CSS
import "./header.css";
// Data
import { AdminNavigationData, EmployeeNavigationData } from "./Navigation";
// Icon
import HeaderLogo from "./header-Logo.svg";
import englishIcon from "../Assets/Header/english.svg";
import frenchIcon from "../Assets/Header/french.svg";
import dutchIcon from "../Assets/Header/dutch.svg";
import downArrowIcon from "../Assets/Header/downArrow.svg";
import dashboardIcon from "../Assets/Header/dashboard.svg";
import myProfileIcon from "../Assets/Header/myProfile.svg";
import changePasswordIcon from "../Assets/Header/changePassword.svg";
import signOutIcon from "../Assets/Header/signOut.svg";
import toggleIcon from "../Assets/Header/toggle.svg";
import closeIcon from "../Assets/Header/close.svg";
import { NotifincationIcon, UserAvatar } from "../Customer/Component/ImageList";
import { useCookies } from "react-cookie";
// Utils
import { profileImageUrl } from "../utils";
//Translation
import { useTranslation } from "react-i18next";

const Header = () => {
  const { isAuthenticated, user, role, isLoggedIn } = useSelector((state) => state.auth);
  const { unreadNotificationId } = useSelector((state) => state.notification);
  const [toggleActive, setToggleActive] = useState(true);
  const [accountClass, setAccountClass] = useState(true);
  // const [language, setLanguage] = useState("en");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const languageList = [
    { name: "English", value: "en", icon: englishIcon },
    { name: "French", value: "fr", icon: frenchIcon },
    { name: "Dutch", value: "du", icon: dutchIcon },
  ];

  const menuList = [
    {
      to: "",
      label: t("customer.navButton.home"),
      icon: (
        <svg width={18} height={18} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_9_22903)">
            <path
              d="M11.6484 13.5611H2.35156C1.65802 13.5611 1.09375 12.9969 1.09375 12.3033V5.68555C1.09375 5.44394 1.28961 5.24805 1.53125 5.24805C1.77289 5.24805 1.96875 5.44394 1.96875 5.68555V12.3033C1.96875 12.5144 2.14047 12.6861 2.35156 12.6861H11.6484C11.8595 12.6861 12.0312 12.5144 12.0312 12.3033V5.68555C12.0312 5.44394 12.2271 5.24805 12.4688 5.24805C12.7104 5.24805 12.9062 5.44394 12.9062 5.68555V12.3033C12.9062 12.9968 12.342 13.5611 11.6484 13.5611Z"
              fill="#343A3F"
            />
            <path
              d="M13.5625 7.21714C13.505 7.21721 13.4481 7.20593 13.395 7.18394C13.3419 7.16194 13.2937 7.12967 13.2532 7.08898L7.81205 1.64793C7.36429 1.20014 6.63572 1.20014 6.18794 1.64793L0.74686 7.089C0.576017 7.25988 0.298997 7.25988 0.128153 7.089C-0.0427178 6.91816 -0.0427178 6.64114 0.128153 6.4703L5.56923 1.02919C6.35815 0.24027 7.64186 0.24027 8.43075 1.02919L13.8718 6.47027C14.0427 6.64114 14.0427 6.91813 13.8718 7.08898C13.8313 7.12967 13.783 7.16195 13.73 7.18394C13.6769 7.20593 13.62 7.21722 13.5625 7.21714ZM8.74999 13.5616H5.24999C5.00836 13.5616 4.81249 13.3657 4.81249 13.1241V9.24126C4.81249 8.48739 5.42581 7.87407 6.17968 7.87407H7.82031C8.57417 7.87407 9.18749 8.48739 9.18749 9.24126V13.1241C9.18749 13.3657 8.99163 13.5616 8.74999 13.5616ZM5.68749 12.6866H8.31249V9.24126C8.31249 8.96987 8.09169 8.74907 7.82031 8.74907H6.17968C5.90829 8.74907 5.68749 8.96987 5.68749 9.24126V12.6866Z"
              fill="#343A3F"
            />
          </g>
          <defs>
            <clipPath id="clip0_9_22903">
              <rect width={14} height={14} fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      to: `${isLoggedIn === false || isLoggedIn === -1 ? "booking" : `${role === "customer" ? "booking" : `${role}/booking/#active`}`}`,
      label: t("labels.bookings"),
      icon: (
        <svg width={18} height={18} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.5 1H10.5V0.5C10.5 0.367392 10.4473 0.240215 10.3536 0.146447C10.2598 0.0526784 10.1326 0 10 0C9.86739 0 9.74021 0.0526784 9.64645 0.146447C9.55268 0.240215 9.5 0.367392 9.5 0.5V1H4.5V0.5C4.5 0.367392 4.44732 0.240215 4.35355 0.146447C4.25979 0.0526784 4.13261 0 4 0C3.86739 0 3.74021 0.0526784 3.64645 0.146447C3.55268 0.240215 3.5 0.367392 3.5 0.5V1H1.5C1.10218 1 0.720644 1.15804 0.43934 1.43934C0.158035 1.72064 0 2.10218 0 2.5V12.5C0 12.8978 0.158035 13.2794 0.43934 13.5607C0.720644 13.842 1.10218 14 1.5 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5V2.5C14 2.10218 13.842 1.72064 13.5607 1.43934C13.2794 1.15804 12.8978 1 12.5 1ZM1.5 2H12.5C12.6326 2 12.7598 2.05268 12.8536 2.14645C12.9473 2.24021 13 2.36739 13 2.5V4H1V2.5C1 2.36739 1.05268 2.24021 1.14645 2.14645C1.24021 2.05268 1.36739 2 1.5 2ZM12.5 13H1.5C1.36739 13 1.24021 12.9473 1.14645 12.8536C1.05268 12.7598 1 12.6326 1 12.5V5H13V12.5C13 12.6326 12.9473 12.7598 12.8536 12.8536C12.7598 12.9473 12.6326 13 12.5 13Z"
            fill="#343A3F"
          />
          <path
            d="M6.00008 10.5001C5.86748 10.5001 5.74033 10.4474 5.64658 10.3536L4.14658 8.85358C4.0555 8.75928 4.00511 8.63298 4.00624 8.50188C4.00738 8.37078 4.05997 8.24537 4.15267 8.15267C4.24538 8.05997 4.37078 8.00738 4.50188 8.00624C4.63298 8.0051 4.75928 8.0555 4.85358 8.14658L6.02658 9.31908L9.17658 6.61908C9.22597 6.57345 9.28406 6.53824 9.34738 6.51558C9.41069 6.49292 9.47792 6.48326 9.54505 6.48718C9.61219 6.4911 9.67784 6.50853 9.73808 6.53841C9.79832 6.56829 9.85192 6.61002 9.89566 6.6611C9.9394 6.71217 9.97239 6.77155 9.99265 6.83567C10.0129 6.89979 10.02 6.96734 10.0136 7.03428C10.0071 7.10121 9.98725 7.16616 9.95512 7.22524C9.92299 7.28431 9.87927 7.33629 9.82658 7.37808L6.32658 10.3781C6.23604 10.4568 6.12007 10.5002 6.00008 10.5001Z"
            fill="#343A3F"
          />
        </svg>
      ),
    },
    {
      to: "about-us",
      label: t("customer.navButton.aboutUs"),
      icon: (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_9_22924)">
            <path
              d="M8 0C3.57841 0 0 3.578 0 8C0 12.4215 3.578 16 8 16C12.4216 16 16 12.422 16 8C16 3.57841 12.422 0 8 0ZM8 14.8837C4.20431 14.8837 1.11628 11.7957 1.11628 8C1.11628 4.20428 4.20431 1.11628 8 1.11628C11.7957 1.11628 14.8837 4.20428 14.8837 8C14.8837 11.7957 11.7957 14.8837 8 14.8837Z"
              fill="#343A3F"
            />
            <path
              d="M7.76331 10.1226C7.32103 10.1226 6.963 10.4912 6.963 10.9334C6.963 11.3652 7.3105 11.7443 7.76331 11.7443C8.21612 11.7443 8.57412 11.3652 8.57412 10.9334C8.57412 10.4912 8.20556 10.1226 7.76331 10.1226ZM7.90018 3.9834C6.47856 3.9834 5.82568 4.82587 5.82568 5.39449C5.82568 5.80518 6.17318 5.99474 6.4575 5.99474C7.02615 5.99474 6.7945 5.18387 7.86859 5.18387C8.39509 5.18387 8.81634 5.41555 8.81634 5.89996C8.81634 6.46859 8.22662 6.79502 7.87912 7.08987C7.57371 7.35309 7.17359 7.78487 7.17359 8.69049C7.17359 9.23806 7.32103 9.39602 7.75275 9.39602C8.26871 9.39602 8.37403 9.16437 8.37403 8.96424C8.37403 8.41668 8.38456 8.10077 8.96375 7.64796C9.24806 7.42684 10.1431 6.71074 10.1431 5.7209C10.1431 4.73105 9.24806 3.9834 7.90018 3.9834Z"
              fill="#343A3F"
            />
          </g>
          <defs>
            <clipPath id="clip0_9_22924">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      to: "how-it-work",
      label: t("customer.navButton.howItWork"),
      icon: (
        <svg width={18} height={18} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 16C6.41775 16 4.87103 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.00346627 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153721C7.99113 -0.15496 9.59966 0.00346627 11.0615 0.608967C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87103 16 6.41775 16 8C15.998 10.1211 15.1545 12.1548 13.6547 13.6547C12.1548 15.1545 10.1211 15.998 8 16ZM8 1.11628C6.63853 1.11628 5.30763 1.52001 4.17561 2.2764C3.04359 3.03279 2.16129 4.10788 1.64027 5.36572C1.11926 6.62355 0.982941 8.00764 1.24855 9.34295C1.51416 10.6783 2.16977 11.9048 3.13248 12.8675C4.09518 13.8302 5.32174 14.4858 6.65705 14.7515C7.99237 15.0171 9.37645 14.8807 10.6343 14.3597C11.8921 13.8387 12.9672 12.9564 13.7236 11.8244C14.48 10.6924 14.8837 9.36147 14.8837 8C14.8818 6.17493 14.1559 4.42517 12.8654 3.13465C11.5748 1.84413 9.82507 1.11825 8 1.11628Z"
            fill="#343A3F"
          />
          <path
            d="M8.74414 12.2794H7.25577C7.10774 12.2794 6.96578 12.2206 6.86111 12.1159C6.75644 12.0112 6.69763 11.8693 6.69763 11.7212C6.69763 11.5732 6.75644 11.4312 6.86111 11.3266C6.96578 11.2219 7.10774 11.1631 7.25577 11.1631H8.74414C8.89217 11.1631 9.03414 11.2219 9.13881 11.3266C9.24348 11.4312 9.30228 11.5732 9.30228 11.7212C9.30228 11.8693 9.24348 12.0112 9.13881 12.1159C9.03414 12.2206 8.89217 12.2794 8.74414 12.2794Z"
            fill="#343A3F"
          />
          <path
            d="M8.00002 12.2787C7.85259 12.2768 7.71174 12.2174 7.60749 12.1131C7.50323 12.0089 7.44381 11.868 7.44188 11.7206V7.03967C7.31618 6.99691 7.20983 6.91074 7.14194 6.79663C7.07406 6.68252 7.04907 6.54794 7.07148 6.41706C7.09389 6.28619 7.16223 6.16759 7.26421 6.08257C7.3662 5.99754 7.49516 5.95166 7.62793 5.95316H8.00002C8.14745 5.95509 8.2883 6.01451 8.39255 6.11877C8.49681 6.22302 8.55623 6.36387 8.55816 6.5113V11.7206C8.55623 11.868 8.49681 12.0089 8.39255 12.1131C8.2883 12.2174 8.14745 12.2768 8.00002 12.2787Z"
            fill="#343A3F"
          />
          <path
            d="M8.00005 5.02353C8.41105 5.02353 8.74423 4.69034 8.74423 4.27934C8.74423 3.86834 8.41105 3.53516 8.00005 3.53516C7.58904 3.53516 7.25586 3.86834 7.25586 4.27934C7.25586 4.69034 7.58904 5.02353 8.00005 5.02353Z"
            fill="#343A3F"
          />
        </svg>
      ),
    },
    {
      to: "help",
      label: t("customer.navButton.help"),
      icon: (
        <svg width={18} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.77966 8.35645V9.71238" stroke="#343A3F" strokeWidth="1.2" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M7.77964 17C8.5285 17 9.13557 16.3929 9.13557 15.644C9.13557 14.8952 8.5285 14.2881 7.77964 14.2881C7.03078 14.2881 6.42371 14.8952 6.42371 15.644C6.42371 16.3929 7.03078 17 7.77964 17Z"
            stroke="#343A3F"
            strokeWidth="1.2"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.2034 12.9318V14.2878C13.2034 15.0366 12.5963 15.6437 11.8475 15.6437H9.13559M2.35593 12.9318C1.60708 12.9318 1 12.3248 1 11.5759V7.7793H2.35593C3.10478 7.7793 3.71186 8.38638 3.71186 9.13523V11.5759C3.71186 12.3248 3.10478 12.9318 2.35593 12.9318Z"
            stroke="#343A3F"
            strokeWidth="1.2"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 7.77966C1 4.03536 4.03536 1 7.77966 1C11.524 1 14.5593 4.03536 14.5593 7.77966H13.2034C12.4545 7.77966 11.8475 8.38675 11.8475 9.13559V11.5763C11.8475 12.3251 12.4545 12.9322 13.2034 12.9322C13.9522 12.9322 14.5593 12.3251 14.5593 11.5763V10.4915"
            stroke="#343A3F"
            strokeWidth="1.2"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];

  const dropDownHandler = (path) => (e) => {
    e.preventDefault();
    navigate(path);
    setAccountClass(true);
  };

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(Logout());
    dispatch({ type: "SEARCH_CLEAR" });
    dispatch({ type: "BOOKING_CLEAR" });
    dispatch({ type: "NOTIFICATION_CLEAR" });
    setAccountClass(true);
    navigate("/");
  };

  const isGoogleTranslate = [
    "/about-us",
    "/rental-information",
    "/how-it-work",
    "/help",
    "/payment-and-reimbursement-terms",
    "/career",
    "/cancellation",
    "/damage",
    "/gdpr",
    "/insurance",
    "/liability-waiver",
    "/privacy-policy",
    "/terms-and-conditions",
    "/car-rental-deals",
  ].includes(window.location.pathname);

  // Google translate
  const googleTranslateElementInit = (language) => {
    if (language === "en") setCookie("googtrans", "/auto/en", 1);
    if (language === "fr") setCookie("googtrans", "/auto/fr", 1);
    if (language === "du") setCookie("googtrans", "/auto/nl", 1);
    new window.google.translate.TranslateElement({ pageLanguage: "en", includedLanguages: "en,fr,nl" }, "google_translate_element");
  };

  const changeLanguageHandler = (e) => {
    if (e.target.value === "en") {
      removeCookie("googtrans", "/auto/fr");
      removeCookie("googtrans", "/auto/nl");
    }
    if (e.target.value === "fr") {
      removeCookie("googtrans", "/en/en");
      removeCookie("googtrans", "/auto/nl");
    }
    if (e.target.value === "du") {
      removeCookie("googtrans", "/auto/en");
      removeCookie("googtrans", "/auto/fr");
    }
    if (isGoogleTranslate === true) {
      i18n.changeLanguage(e.target.value);
      googleTranslateElementInit(e.target.value);
    } else i18n.changeLanguage(e.target.value);
    setToggleActive(!toggleActive);
  };

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const closeHandler = () => setIsModalOpen(false);

  const DropDownListItem = ({ clickHandler, icon, alt, label }) => (
    <li className="list-unstyled d-flex align-items-center">
      <NavLink className="text-decoration-none ff_md f14" onClick={clickHandler}>
        <span className="ms-2 d-flex">
          <img src={icon} className="img-fluid" alt={alt} />
          <p className="m-0 ms-2">{label}</p>
        </span>
      </NavLink>
    </li>
  );

  const MenuListItem = ({ linkTo, closeHandler, icon, label }) => (
    <div className="col-6 col-sm-4 col-md-4">
      <NavLink to={linkTo} className="text-decoration-none ff_md f14 txt-dark" onClick={closeHandler}>
        <span className="mobileCircle">{icon}</span>
        {label}
      </NavLink>
    </div>
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Outside
  const myRef = useRef();
  const myRef2 = useRef();
  const handleClickOutside = (e) => {
    if (myRef?.current !== undefined && !myRef?.current?.contains(e?.target)) setToggleActive(true);
    if (myRef2?.current !== undefined && !myRef2?.current?.contains(e?.target)) setAccountClass(true);
  };

  useEffect(() => {
    if (i18n?.language?.includes("en")) i18n.changeLanguage("en");
  }, []);

  useEffect(() => {
    if (isAuthenticated === true) window?.flutter_inappwebview?.callHandler("handlerMessage", true);
    else window?.flutter_inappwebview?.callHandler("handlerMessage", false);
  }, [isAuthenticated]);

  const language = languageList.filter((ele) => {
    return ele.value === (i18n?.language || "en");
  })[0];

  // Add Body Class
  useEffect(() => {
    if (i18n?.language?.includes("en")) {
      document.body.classList.add("english");
      document.body.classList.remove("france", "dutch");
    } else if (i18n?.language?.includes("fr")) {
      document.body.classList.add("france");
      document.body.classList.remove("english", "dutch");
    } else if (i18n?.language?.includes("du")) {
      document.body.classList.add("dutch");
      document.body.classList.remove("english", "france");
    }
  }, [i18n?.language]);

  useEffect(() => {
    if ((isLoggedIn === true && user === undefined) || (isLoggedIn === false && isAuthenticated === undefined) || (isLoggedIn === -1 && isAuthenticated === -1)) {
      dispatch(LoadUser());
    }
  }, [dispatch]);

  console.log("user", user);

  return (
    <div className="header d-flex justify-content-between align-items-center pe-3 px-xl-5 position-fixed w-100 top-0 c-primary notranslate" id="header">
      {/* Header Icon Start */}
      <div className="d-flex align-items-center">
        {location.pathname !== "/" && (
          <div className="d-md-none ps-3" onClick={() => navigate(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
              <path
                d="M31.5 16C31.5 24.5609 24.5604 31.5 15.9986 31.5C7.437 31.5 0.5 24.561 0.5 16C0.5 7.43901 7.43965 0.5 15.9986 0.5C24.5578 0.5 31.5 7.43912 31.5 16Z"
                stroke="white"
                strokeOpacity="0.2"
              />
              <path d="M18 20.8874L13 15.9142L18 10.9409" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        )}
        <div className="px-3 header-logo">
          <img src={HeaderLogo} alt="header logo" className="img-fluid" onClick={(e) => navigate("/")} />
        </div>
      </div>
      {/* Header Icon End */}

      {/* Customer Naviattion Link Start */}
      {!["admin", "employee"].includes(window.location.pathname.split("/")[2]) && (
        <div className="header-menubar d-none d-lg-flex align-items-center mainMenu">
          <ul className={`m-0 p-0 d-flex menu-navigationbar`}>
            {menuList.map((ele, index) => {
              return (
                <li className="list-unstyled" key={index}>
                  <NavLink to={ele?.to} className="text-decoration-none ff_md f14 opacity-50 txt-light">
                    {ele?.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {/* Customer Navigation Link End */}

      <div className="header-menubar">
        <div className="headerHandler d-flex align-items-center GapOne">
          {/* Language Handler Start */}
          <div id="google_translate_element"></div>
          <div ref={myRef} className={`headerLaunguage border-n ${toggleActive ? "" : "is-active"}`}>
            <button className="px-2 ff_md f14 border-s c-light d-flex align-items-center txt-primary" onClick={(e) => setToggleActive(!toggleActive)}>
              <img src={language?.icon} alt="Language Icon" className="img-fluid me-2 languageIcon d-inline" />
              <span>{language?.name}</span>
              <img src={downArrowIcon} alt="language down arrow" className="img-fluid ms-1 arrowDown" />
            </button>
            <ul className={`m-0 p-0 px-3 py-3 border-1 border-n bg-white`}>
              {languageList?.map((ele, index) => {
                return (
                  <li className="list-unstyled" key={index}>
                    <label htmlFor={ele.value} className="d-flex align-items-center">
                      <input
                        type="radio"
                        name="language"
                        id={ele?.value}
                        value={ele?.value}
                        onChange={changeLanguageHandler}
                        checked={(i18n?.language || "en") === ele.value}
                        className="form-check-input"
                      />
                      <span className="ms-2 d-flex">
                        <img src={ele?.icon} className="img-fluid" alt={ele?.name} />
                        <p className="m-0 ms-2">{ele?.name}</p>
                      </span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* Language Handler End */}

          {/* Notification Start */}
          {(role === "admin" || role === "employee" || role === "customer") && (
            <button
              className="bg-transparent"
              onClick={() => {
                navigate(`/${role}/notification`);
                dispatch(SetNotificationRead(unreadNotificationId));
              }}
            >
              <div className="adminNotificationSquare position-relative">
                <img src={NotifincationIcon} alt="" />
                {unreadNotificationId !== undefined && unreadNotificationId?.length !== 0 && (
                  <div className="rounded-circle bg-dark text-white d-flex align-items-center justify-content-center">{unreadNotificationId?.length}</div>
                )}
              </div>
            </button>
          )}
          {/* Notification End */}
          {isAuthenticated === true && (
            <div ref={myRef2} className={`headerAccount  ${accountClass ? "" : "is-active"}`}>
              <button className="bg-transparent d-flex align-items-center ff_bd f14 px-0 text-truncate" onClick={(e) => setAccountClass(!accountClass)}>
                <img
                  src={user?.profilePic !== null ? profileImageUrl + "/" + user?.profilePic : UserAvatar}
                  alt=""
                  className="img-fluid me-0 me-lg-2 userAvatar"
                  onError={(event) => (event.target.src = UserAvatar)}
                />
                <span className="headerUsername d-none d-lg-inline txt-light">{`${user?.firstName} ${user?.lastName}`}</span>
              </button>

              <ul className="m-0 p-0 px-3 py-3 border-n bg-white">
                <DropDownListItem label={t("customer.navButton.myProfile")} icon={myProfileIcon} clickHandler={dropDownHandler(`/${role}/profile`)} alt={"My Profile"} />
                {["admin", "employee"].includes(role) && (
                  <DropDownListItem label={t("admin.dashboard.headers.dashboard")} icon={dashboardIcon} clickHandler={dropDownHandler(`/${role}/dashboard`)} alt={"Dashboard"} />
                )}
                <DropDownListItem
                  label={t("customer.navButton.changePass")}
                  icon={changePasswordIcon}
                  clickHandler={dropDownHandler(`/auth/${role}/change-password`)}
                  alt={"Change Password"}
                />
                <DropDownListItem label={t("customer.navButton.signOut")} icon={signOutIcon} clickHandler={logoutHandler} alt={"Sign Out"} />
              </ul>
            </div>
          )}
          {isAuthenticated === false && (
            <button className="border-n px-2 ff_md f14 c-secondary headerlogIn txt-primary px-3 px-sm-5" onClick={() => navigate(`/auth/customer/${btoa(location.pathname)}`)}>
              <span className="d-md-none">
                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 0.5C4.567 0.5 3 2.067 3 4C3 5.933 4.567 7.5 6.5 7.5C8.433 7.5 10 5.933 10 4C10 2.067 8.433 0.5 6.5 0.5ZM4 4C4 2.61925 5.11925 1.5 6.5 1.5C7.88075 1.5 9 2.61925 9 4C9 5.38075 7.88075 6.5 6.5 6.5C5.11925 6.5 4 5.38075 4 4Z"
                    fill="#0E344B"
                  />
                  <path
                    d="M12.4452 8.86836C12.3969 8.91279 12.3403 8.94725 12.2786 8.96978C12.2169 8.99231 12.1514 9.00248 12.0858 8.99969C12.0202 8.99691 11.9558 8.98123 11.8962 8.95355C11.8367 8.92586 11.7832 8.88673 11.7388 8.83836L10.1318 7.08836C10.047 6.9961 9.99997 6.87539 9.99997 6.75011C9.99997 6.62484 10.047 6.50412 10.1318 6.41186L11.7388 4.66186C11.7832 4.61349 11.8367 4.57433 11.8962 4.54664C11.9558 4.51895 12.0202 4.50325 12.0858 4.50045C12.1514 4.49766 12.217 4.50781 12.2787 4.53034C12.3403 4.55286 12.397 4.58732 12.4454 4.63174C12.4938 4.67616 12.5329 4.72967 12.5606 4.78922C12.5883 4.84877 12.604 4.9132 12.6068 4.97881C12.6096 5.04443 12.5994 5.10995 12.5769 5.17164C12.5544 5.23334 12.5199 5.28999 12.4755 5.33836L11.638 6.25011H15C15.1326 6.25011 15.2598 6.30279 15.3536 6.39656C15.4473 6.49033 15.5 6.61751 15.5 6.75011C15.5 6.88272 15.4473 7.0099 15.3536 7.10367C15.2598 7.19743 15.1326 7.25011 15 7.25011H11.638L12.4755 8.16186C12.5199 8.21022 12.5544 8.26686 12.5769 8.32853C12.5995 8.39021 12.6096 8.45572 12.6068 8.52133C12.604 8.58693 12.5884 8.65135 12.5607 8.71089C12.533 8.77044 12.4939 8.82395 12.4455 8.86836H12.4452ZM4.8825 8.50011C2.46175 8.50011 0.5 10.4621 0.5 12.8826C0.5 14.3279 1.672 15.5001 3.1175 15.5001H5.25C5.38261 15.5001 5.50979 15.4474 5.60355 15.3537C5.69732 15.2599 5.75 15.1327 5.75 15.0001C5.75 14.8675 5.69732 14.7403 5.60355 14.6466C5.50979 14.5528 5.38261 14.5001 5.25 14.5001H3.1175C2.68851 14.5001 2.27709 14.3297 1.97375 14.0264C1.67041 13.723 1.5 13.3116 1.5 12.8826C1.5 11.0141 3.01425 9.50011 4.8825 9.50011H8.1175C9.986 9.50011 11.5 11.0144 11.5 12.8826C11.5 13.3116 11.3296 13.723 11.0262 14.0264C10.7229 14.3297 10.3115 14.5001 9.8825 14.5001H7.75C7.61739 14.5001 7.49021 14.5528 7.39645 14.6466C7.30268 14.7403 7.25 14.8675 7.25 15.0001C7.25 15.1327 7.30268 15.2599 7.39645 15.3537C7.49021 15.4474 7.61739 15.5001 7.75 15.5001H9.8825C11.3277 15.5001 12.5 14.3281 12.5 12.8826C12.5 10.4619 10.538 8.50011 8.1175 8.50011H4.8825Z"
                    fill="#0E344B"
                  />
                </svg>
              </span>
              <span className="d-none d-md-inline">{t("buttons.login")}</span>
            </button>
          )}

          {((window.location.pathname.split("/")[1] !== "admin" && window.location.pathname.split("/")[1] !== "employee") || role === "admin" || role === "employee") && (
            <img src={toggleIcon} alt="" className="toggleButton d-lg-none" onClick={showModal} />
          )}
          <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={closeHandler}
            footer={[]}
            className="CustomerheaderModel"
            closable={false}
            width={"100%"}
            style={{
              top: 0,
              margin: 0,
              maxWidth: "100%",
              borderRadius: "0",
              height: "100vh",
              overflow: "hidden",
            }}
            maskClosable={false}
          >
            <div className="header d-flex align-items-center justify-content-between px-3 px-xl-5 c-primary">
              <div className="header-logo">
                <img src={HeaderLogo} alt="header logo" className="img-fluid" />
              </div>
              <img src={closeIcon} alt="" className="toggleButton d-lg-none img-fluid" onClick={closeHandler} />
            </div>
            {["customer", undefined, -1].includes(role) && (
              <div className="row mx-3 mx-xl-5 gy-4 pt-4 pb-4 customerMenubar">
                {menuList.map((ele, index) => {
                  return <MenuListItem linkTo={ele?.to} closeHandler={closeHandler} icon={ele?.icon} label={ele?.label} key={index} />;
                })}
              </div>
            )}
            <div className={`row mx-0 mx-xl-0 gy-5 pt-5 pb-5 adminMenubar ${role === "admin" || role === "employee" ? "" : "d-none"}`}>
              {(role === "admin" ? AdminNavigationData : EmployeeNavigationData)?.map((ele, index) => {
                return (
                  <div className="col-4 col-sm-4 col-md-3 text-center" key={index}>
                    <NavLink to={`${role}/${ele?.path}`} className="NavigationLink ff_rg f12" data-image={ele?.label} onClick={closeHandler}>
                      <div className="NavIconBG d-flex align-items-center justify-content-center m-auto">{ele?.svg}</div>
                      <span className="mt-2 txt-light-md ff_rg">{t(ele?.label)}</span>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Header;
