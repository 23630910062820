import axios from "axios";
import { url, dispatchLoading, dispatchError, catchHandler, elseHandler, dispatchToast } from "../../utils";
import { FetchBookingCancelReason } from "../../Customer/redux/Booking";
import { BookingData } from "./Dashboard";

const bookingUrl = url + "booking";

export const FetchProfilePic = (customerId) => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  axios
    .get(url + "customer/profile", { params: { customerId }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "BOOKING_PROFILEPIC_SUCCESS", payload: { name: customerId, value: result?.data?.data } });
        dispatchError(dispatch, "booking", undefined);
      }
      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const FetchCarDropDown = () => async (dispatch) => {
  dispatchLoading(dispatch, "car", true);
  axios
    .get(url + "car/dropdown/list", { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "CAR_DROPDOWN_LIST", payload: result?.data?.data });
        dispatchError(dispatch, "car", undefined);
      }
      dispatchLoading(dispatch, "car", false);
    })
    .catch(catchHandler(dispatch, "car"));
};
export const SearchBookingWithRefId = (refId, carId, pageId) => async (dispatch) => {
  dispatchLoading(dispatch, "bookingList", true);
  let tempCarId = carId && carId !== 0 ? carId : "";
  axios
    .get(bookingUrl + "/search", { params: { search: refId, carId: tempCarId, pageId: pageId || 0, itemPerPage: 5 }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "BOOKING_SEARCH_SUCCESS", payload: { data: result?.data?.data?.data, pageId: pageId || 0, searchCount: result?.data?.data?.count } });
        dispatchError(dispatch, "booking", undefined);
      }
      dispatchLoading(dispatch, "bookingList", false);
    })
    .catch(catchHandler(dispatch, "bookingList"));
};
export const FetchBookingListWithCategoryAndCarId = (queryData, isCarId, pageId, itemPerPage) => async (dispatch) => {
  dispatchLoading(dispatch, "bookingList", true);
  let searchParams = isCarId ? { carId: queryData } : { rideStatus: queryData };
  searchParams = { ...searchParams, pageId: pageId ?? 0, itemPerPage: itemPerPage ?? 10, noCache: 1 };
  axios
    .get(bookingUrl + "/list/admin", { params: searchParams, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        if (!isCarId) {
          dispatch({ type: "BOOKING_LIST_TAB_SUCCESS", payload: { type: queryData, data: result?.data?.data, pageId: pageId ?? 0 } });
        } else {
          dispatch({ type: "CAR_BOOKING_LIST_SUCCESS", payload: result?.data?.data });
        }
        dispatchError(dispatch, "booking", undefined);
      }
      dispatchLoading(dispatch, "bookingList", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const FetchBookingList = () => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  axios
    .get(bookingUrl + "/list/admin", { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "BOOKING_LIST_SUCCESS", payload: result?.data?.data });
        dispatchError(dispatch, "booking", undefined);
      } else elseHandler(dispatch, "booking", result?.data);

      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const FetchBookingUpdate = (bookingId) => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  axios
    .get(bookingUrl + "/list/admin/update", { params: { bookingId }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "BOOKING_LIST_UPDATE_SUCCESS", payload: result?.data?.data });
        dispatchError(dispatch, "booking", undefined);
      } else elseHandler(dispatch, "booking", result?.data);

      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const FetchBookingReload = (bookingId) => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  axios
    .get(bookingUrl, { params: { bookingId }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "BOOKING_LOAD_SUCCESS", payload: result?.data?.data });
        dispatchError(dispatch, "booking", undefined);
      } else elseHandler(dispatch, "booking", result?.data);

      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const AddReasonCancel = (reason, setIsModalOpen) => async (dispatch) => {
  axios
    .post(bookingUrl + "/reason", { reason }, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(FetchBookingCancelReason());
        dispatchError(dispatch, "booking", undefined);
        dispatchToast(dispatch, "success", "Cancellation reason added successfully");
        setIsModalOpen(false);
      } else elseHandler(dispatch, "booking", result?.data);

      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const DeleteReasonCancel = (reasonId, setIsModalOpen) => async (dispatch) => {
  axios
    .delete(bookingUrl + "/reason", { data: { reasonId }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(FetchBookingCancelReason());
        dispatchError(dispatch, "booking", undefined);
        dispatchToast(dispatch, "success", "Cancellation reason deleted successfully");
        setIsModalOpen(false);
      } else elseHandler(dispatch, "booking", result?.data);

      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const DeleteCarRidePhoto = (photoId, bookingId, type, name) => async (dispatch) => {
  dispatchLoading(dispatch, "car", true);
  axios
    .delete(bookingUrl + "/ride/upload", { data: { photoId: photoId, bookingId: bookingId, type: type, name: name }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatchError(dispatch, "car", undefined);
      } else elseHandler(dispatch, "car", result?.data);
      dispatchLoading(dispatch, "car", false);
    })
    .catch(catchHandler(dispatch, "car"));
};
export const StartRide = (data, successCB) => async (dispatch) => {
  axios
    .post(bookingUrl + "/ride/start", data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(FetchBookingListWithCategoryAndCarId("upcoming", false));
        dispatch(BookingData());
        dispatchError(dispatch, "booking", undefined);
        dispatchToast(dispatch, "success", "Ride Started");
        successCB();
      } else elseHandler(dispatch, "booking", result?.data);
      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const EndRide = (data, successCB) => async (dispatch) => {
  axios
    .post(bookingUrl + "/ride/end", data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(FetchBookingListWithCategoryAndCarId("active", false));
        dispatch(BookingData());
        dispatchError(dispatch, "booking", undefined);
        dispatchToast(dispatch, "success", "Ride has been completed successfully!");
        successCB();
      } else elseHandler(dispatch, "booking", result?.data);
      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const ApproveRide = (data, setModalOpen, navigate, role) => async (dispatch) => {
  let noCacheParams = { noCache: 1 };
  axios
    .post(bookingUrl + "/ride/approve", data, { params: noCacheParams, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(FetchBookingListWithCategoryAndCarId("request", false));
        dispatch(BookingData());
        dispatchError(dispatch, "booking", undefined);
        setModalOpen(false);
        dispatchToast(dispatch, "success", `Ride has been ${data?.rideApproved ? "Approved" : "Rejected"}`);
        if (data?.rideApproved === true) {
          navigate(`/${role}/booking/#upcoming`);
        } else {
          navigate(`/${role}/booking#cancelled`);
        }
      } else elseHandler(dispatch, "booking", result?.data);
      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const PartialBookingRefund = (data, setModalOpen, selectedBookingType) => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  let noCacheParams = { noCache: 1 };
  axios
    .put(bookingUrl + "/partial-refund", data, { params: noCacheParams, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(BookingData());
        dispatch(FetchBookingListWithCategoryAndCarId(selectedBookingType, false));
        dispatchError(dispatch, "booking", undefined);
        setModalOpen(false);
      } else elseHandler(dispatch, "booking", result?.data);
      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const AdminCancelBookingAPI = (bookingId, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  axios
    .put(
      bookingUrl + "/cancel",
      {
        bookingId,
        reason: "ADMIN CANCEL",
        comment: "ADMIN_CANCEL",
      },
      { withCredentials: true }
    )
    .then((result) => {
      if (result?.data?.success) {
        dispatchToast(dispatch, "success", result?.data?.msg);
        navigate("/booking#cancelled");
        // dispatchError(dispatch, "booking", undefined);
      } else elseHandler(dispatch, "booking", result?.data);
      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
