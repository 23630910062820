import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./Assets/locales/en/translation.json";
import translationFR from "./Assets/locales/fr/translation.json";
import translationDU from "./Assets/locales/du/translation.json";

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "en",
		debug: true,
		resources: {
			en: {
				translation: translationEN,
			},
			fr: {
				translation: translationFR,
			},
			du: {
				translation: translationDU,
			},
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
