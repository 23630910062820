import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, isSupported, getToken } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";
import { FCM_TOKEN, GetNotification } from "./Redux/Notification";
import { useEffect } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyD839j4Qeq7nAh9i-sNiefim0Xv4WDzjmA",
  authDomain: "dionyrental-fa520.firebaseapp.com",
  projectId: "dionyrental-fa520",
  storageBucket: "dionyrental-fa520.appspot.com",
  messagingSenderId: "85622058624",
  appId: "1:85622058624:web:ebd1900b1f760866fa0065",
  measurementId: "G-FSJ141N8CJ",
};

const app = initializeApp(firebaseConfig);

const FetchNotification = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    isSupported().then((result) => {
      if (result && isAuthenticated === true) {
        const messaging = getMessaging(app);
        navigator?.serviceWorker
          ?.register("/firebase-messaging-sw.js")
          .then((registration) => {
            getToken(messaging, {
              serviceWorkerRegistration: registration,
              vapidKey: "BGe6cwb_CfjQtr77ZHucdju8C7OuhGV-A0OiVRbbEO6qh_uAAm2StH7_WnbyQEquNlNsP1m5M7MQF7ssxURmEW8",
            })
              .then((currentToken) => {
                if (currentToken) {
                  dispatch(FCM_TOKEN(currentToken));
                }
              })
              .catch((err) => console.error(err));
          })
          .catch((error) => {
            console.error("ERR__ERR__ERR:", error);
          });
        onMessage(messaging, function (payload) {
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
            icon: "/dionyrental/favicon.ico",
          };
          dispatch(GetNotification(0));
          try {
            if (!("Notification" in window)) {
              console.error("This browser does not support system notifications.");
            } else if (Notification?.permission === "granted") {
              var notification = new Notification(notificationTitle, notificationOptions);
              console.log("payload", payload);
              notification.onclick = function (event) {
                event.preventDefault();
                window.open(payload?.fcmOptions?.link, "_blank");
                notification.close();
              };
            }
          } catch (err) {
            console.error(err);
          }
        });
      }
    });
  }, [isAuthenticated, dispatch]);

  return <></>;
};

export { FetchNotification };
