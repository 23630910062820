import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";

import "./Assets/Index.css";
import "./Assets/Color.css";

import store from "./store";

import ToastHandler from "./ToastHandler";
import Header from "./Components/Header";

import "./i18n";
import { FetchNotification } from "./firebase";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallbackPage, SiteLoader, alias } from "./utils";
import { AdminNavigationData, EmployeeNavigationData } from "./Components/Navigation";
import { CookiesProvider } from "react-cookie";
// CSS
import "./Admin/css/style.css";
import "./Admin/css/Header.css";
import "./Admin/css/NavigationBar.css";
import "./Admin/css/Index.css";
import "./Admin/css/Card.css";
import "./Admin/css/Button.css";
import "./Admin/css/InputBox.css";
import "./Admin/css/Add.css";
import "./Admin/css/SearchBar.css";
import "./Admin/css/premiumCar.css";
import "./Auth/Auth.css";
import "./Customer/CSS/pages.css";
import "./Customer/CSS/MediaQuery.css";
import "./Customer/CSS/Components.css";
import "./Customer/CSS/pages.css";
import "./Customer/CSS/style.css";
import "./Customer/CSS/Fix.css";

// const AuthRoutes = lazy(() => import("./Auth/Routes"));
// const CustomerRoute = lazy(() => import("./Customer/Routes"));
// const AdminRoutes = lazy(() => import("./Admin/Routes"));
// const StaticRoutes = lazy(() => import("./Static/Routes"));

/** Test View Start */
const Dashboard = lazy(() => import("./Admin/views/Dashboard"));
const Customer = lazy(() => import("./Admin/views/Customer"));
const Employee = lazy(() => import("./Admin/views/Employee"));
const Promocode = lazy(() => import("./Admin/views/Promocode"));
const Locations = lazy(() => import("./Admin/views/Location"));
const Notification = lazy(() => import("./Admin/views/Notification"));
const Booking = lazy(() => import("./Admin/views/Booking"));
const Cars = lazy(() => import("./Admin/views/Cars"));
const CarAdd = lazy(() => import("./Admin/views/Car/Add"));
const CarDetail = lazy(() => import("./Admin/views/Car/Detail"));
const Earning = lazy(() => import("./Admin/views/Earning"));
const Profile = lazy(() => import("./Admin/views/Profile"));
const StaticPage = lazy(() => import("./Admin/views/StaticPage"));
const EmailTemplates = lazy(() => import("./Admin/views/EmailTemplates"));
const NotificationTemplate = lazy(() => import("./Admin/views/NotificationTemplate"));
const Setting = lazy(() => import("./Admin/views/Setting"));
const SignIn = lazy(() => import("./Auth/views/SignIn"));
const SignUp = lazy(() => import("./Auth/views/SignUp"));
const ResetPassword = lazy(() => import("./Auth/views/ResetPassword"));
const ChangePassword = lazy(() => import("./Auth/views/ChangePassword"));
const BookingEdit = lazy(() => import("./Customer/views/Booking/Edit"));
const Home = lazy(() => import("./Customer/Pages/Home/Home"));
const Help = lazy(() => import("./Customer/Pages/Help/Help"));
const HelpList = lazy(() => import("./Customer/Pages/Help/HelpList"));
const HelpBlog = lazy(() => import("./Customer/Pages/Help/HelpBlog"));
const Search = lazy(() => import("./Customer/views/Search"));
const SearchDetails = lazy(() => import("./Customer/views/SearchDetails"));
const Booking111 = lazy(() => import("./Customer/views/Booking/Booking"));
const BookingList = lazy(() => import("./Customer/views/Booking/List"));
const BookingConfirm = lazy(() => import("./Customer/views/Booking/Confirm"));
const BookingFailurePage = lazy(() => import("./Customer/views/Booking/Failure"));
const BookingDetail = lazy(() => import("./Customer/views/Booking/Details"));
const CProfile = lazy(() => import("./Customer/views/Profile"));
const CNotification = lazy(() => import("./Customer/views/Notification"));
const PageNotFound = lazy(() => import("./Customer/Pages/Error/Error"));
const RentalInformation = lazy(() => import("./Static/views/RentalInformation"));
const PaymentTerms = lazy(() => import("./Static/views/PaymentAndReimbursementTerms"));
const Cancellation = lazy(() => import("./Static/views/Cancellation"));
const Damage = lazy(() => import("./Static/views/Damage"));
const GDPR = lazy(() => import("./Static/views/GDPR"));
const Insurance = lazy(() => import("./Static/views/Insurance"));
const LiabilityWaiver = lazy(() => import("./Static/views/LiabilityWaiver"));
const Career = lazy(() => import("./Static/views/Career"));
const CarRentalDeals = lazy(() => import("./Static/views/CarRentalDeals"));
const OurLocations = lazy(() => import("./Static/views/OurLocations"));
const HowItWorks = lazy(() => import("./Static/views/HowItWorks"));
const AboutUs = lazy(() => import("./Static/views/AboutUs"));
const CarRental = lazy(() => import("./Static/views/CarRental"));
const Privacy = lazy(() => import("./Static/views/Privacy"));
const Terms = lazy(() => import("./Static/views/Terms"));
const AdIndex = lazy(() => import("./Admin/Index"));
const AuIndex = lazy(() => import("./Auth/Index"));
const CIndex = lazy(() => import("./Customer/Index"));
const JobApplicants = lazy(() => import("./Admin/views/JobApplicants"));

const Sitemap = lazy(() => import("./Customer/views/sitemap"));

/** Test View End */

function requestPermission() {
  try {
    Notification?.requestPermission();
  } catch (err) {}
}
requestPermission();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          {/* basename={alias}> */}
          <Suspense fallback={<SiteLoader />}>
            <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
              <FetchNotification />
              <Header />
              {/* <AuthRoutes />
            <AdminRoutes />
            <CustomerRoute />
          <StaticRoutes /> */}

              {/* Test Start */}
              <Routes>
                <Route path="/admin" element={<AdIndex roleValue={"admin"} navigationData={AdminNavigationData} />}>
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="booking" element={<Booking />} />
                  <Route path="booking/edit/:bId" element={<BookingEdit />} />
                  <Route path="cars">
                    <Route path="" element={<Cars />} />
                    <Route path="add" element={<CarAdd />} />
                    <Route path="edit/:carId" element={<CarAdd />} />
                    <Route path="duplicate/:carId" element={<CarAdd />} />
                    <Route path="detail/:carId" element={<CarDetail />} />
                  </Route>
                  <Route path="customer" element={<Customer />} />
                  <Route path="earning" element={<Earning />} />
                  <Route path="employee" element={<Employee />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="location" element={<Locations />} />
                  <Route path="promocode" element={<Promocode />} />
                  <Route path="static-pages" element={<StaticPage />} />
                  <Route path="email-templates" element={<EmailTemplates />} />
                  <Route path="notification-templates" element={<NotificationTemplate />} />
                  <Route path="settings" element={<Setting />} />
                  <Route path="notification" element={<Notification />} />
                  <Route path="job-applicants" element={<JobApplicants />} />
                </Route>
                <Route path="/employee" element={<AdIndex roleValue={"employee"} navigationData={EmployeeNavigationData} />}>
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="booking" element={<Booking />} />
                  <Route path="customer" element={<Customer />} />
                  <Route path="cars" element={<Cars />} />
                  <Route path="cars/detail/:carId" element={<CarDetail />} />
                  <Route path="notification" element={<Notification />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="booking/edit/:bId" element={<BookingEdit />} />
                </Route>
                <Route path="/auth" element={<AuIndex />}>
                  <Route path="signup" element={<SignUp />} />
                  <Route exact path=":roleName" element={<SignIn />} />
                  <Route exact path=":roleName/reset-password" element={<ResetPassword />} />
                  <Route exact path=":roleName/change-password" element={<ChangePassword />} />
                  <Route exact path=":roleName/change-password/:token" element={<ChangePassword />} />
                  <Route path=":roleName/:returnUrl" element={<SignIn />} />
                </Route>
                <Route path="/" element={<CIndex />}>
                  <Route exact path="" element={<Home />} />

                  <Route exact path="help">
                    <Route path="" element={<Help />} />
                    <Route path=":_id" element={<HelpList />} />
                    <Route path="list/:_id" element={<HelpBlog />} />
                  </Route>

                  <Route path="booking">
                    <Route path="" element={<BookingList />} />
                    <Route path=":bId" element={<Booking111 />} />
                    <Route path="true/:bId" element={<BookingConfirm />} />
                    <Route path="false/:bId" element={<BookingFailurePage />} />
                    <Route path="edit/:bId" element={<BookingEdit />} />
                    <Route path="detail/:bId" element={<BookingDetail />} />
                  </Route>

                  <Route exact path="customer/profile" element={<CProfile />} />
                  <Route exact path="customer/notification" element={<CNotification />} />

                  <Route exact path="search/detail/:queryString/:priorityId" element={<SearchDetails />} />
                  <Route exact path="search/:queryString/:priorityId" element={<Search />} />
                  <Route exact path="about-us" element={<AboutUs />} />
                  <Route exact path="how-it-work" element={<HowItWorks />} />
                  <Route exact path="rental-information" element={<RentalInformation />} />
                  <Route exact path="our-locations" element={<OurLocations />} />
                  <Route exact path="car-rental-deals" element={<CarRentalDeals />} />
                  <Route exact path="payment-and-reimbursement-terms" element={<PaymentTerms />} />
                  <Route exact path="career" element={<Career />} />
                  <Route exact path="cancellation" element={<Cancellation />} />
                  <Route exact path="damage" element={<Damage />} />
                  <Route exact path="gdpr" element={<GDPR />} />
                  <Route exact path="insurance" element={<Insurance />} />
                  <Route exact path="liability-waiver" element={<LiabilityWaiver />} />

                  <Route exact path="privacy-policy" element={<Privacy />} />
                  <Route exact path="terms-and-conditions" element={<Terms />} />
                  <Route exact path="sitemap" element={<Sitemap />} />
                  <Route exact path="car-rental" element={<CarRental />} />
                  <Route path="*" element={<PageNotFound />} />
                </Route>
              </Routes>
              {/* Test End */}
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
        <ToastHandler />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);
